import React from 'react'
// Ant Design
import { Flex, Typography, Input, Select, Button } from 'antd'
// Icons
import { PlusOutlined } from '@ant-design/icons'
// Configuraciones
import { getCongif } from '../../../config'

const { Title, Text } = Typography
const { TextArea } = Input

export const Formulario = () => {
    return (
        <Flex vertical style={{marginTop: "10px", padding: "0 60px"}}>

            <Flex style={{width: "100%"}}>
                <Flex vertical style={{width: "50%", padding: "0 10px"}}>
                    <Title level={4} style={{color: getCongif("colorSecundario")}}>Nombre del Template</Title>
                    <Input placeholder="Nombre" size="large" />
                </Flex>
                <Flex vertical style={{width: "50%", padding: "0 10px"}}>
                    <Title level={4} style={{color: getCongif("colorSecundario")}}>Idioma del Template</Title>
                    <Select
                        defaultValue="spamx"
                        size="large"
                        options={[
                            { value: 'spamx', label: 'Spanish (MEX)' },
                            { value: 'spaep', label: 'Spanish (ESP)' },
                            { value: 'engue', label: 'English (EU)' },
                            { value: 'engen', label: 'English (ENG)' },
                        ]}
                    />
                </Flex>
            </Flex>

            <Title level={4} style={{color: getCongif("colorSecundario"), textAlign: "center", marginTop: "30px"}}>Configuración de contenido - WhatsApp Carta</Title>
            <div style={{height: "1px", width: "100%", backgroundColor: getCongif("colorSecundario")}}></div>

            <Flex vertical style={{marginTop: "20px"}}>
                <Title level={4} style={{color: getCongif("colorSecundario")}}>Cuerpo</Title>
                <TextArea
                    showCount
                    maxLength={1600}
                    placeholder="Cuerpo del mensaje..."
                    size="large"
                    autoSize={{
                        minRows: 3,
                        maxRows: 6,
                    }}
                />
                <Flex justify="center">
                    <Button type="ghost" size="small" icon={<PlusOutlined />} style={{marginTop: "10px", fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>AGREGAR VARIABLE</Button>
                </Flex>
            </Flex>

            <Flex vertical style={{marginTop: "10px"}}>
                <Title level={4} style={{color: getCongif("colorSecundario")}}>Tipo de Encabezado</Title>
                <Select
                    defaultValue="media"
                    size="large"
                    options={[
                        { value: 'media', label: 'Media' },
                        { value: 'text', label: 'Texto' },
                    ]}
                />
            </Flex>

            <Flex vertical style={{marginTop: "20px"}}>
                <Title level={4} style={{color: getCongif("colorSecundario")}}>Media URL</Title>
                <Input placeholder="http://" size="large" showCount maxLength={1600} />
                <Text type="secondary" style={{fontSize: "12px", paddingLeft: "10px"}}>Soporta imagenes, audio, video y documentos.</Text>
                <Flex justify="center">
                    <Button type="ghost" size="small" icon={<PlusOutlined />} style={{fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>AGREGAR VARIABLE</Button>
                </Flex>
            </Flex>

            <Flex vertical style={{marginTop: "10px"}}>
                <Title level={4} style={{color: getCongif("colorSecundario")}}>Pie de Página</Title>
                <Input placeholder="Mensaje" size="large" showCount maxLength={60} />
            </Flex>

            <Flex vertical style={{marginTop: "20px"}}>
                <Title level={4} style={{color: getCongif("colorSecundario")}}>Tipo e Boton</Title>
                <Select
                    defaultValue="select"
                    size="large"
                    options={[
                        { value: 'select', label: 'Selecciona', disabled: true },
                        { value: 'spaep', label: 'Quick Reply' },
                        { value: 'engue', label: 'Call to Action' },
                    ]}
                />
                <Text type="secondary" style={{fontSize: "12px", paddingLeft: "10px"}}>Crea botones que permitan a los clientes responder a su mensaje o tomar medidas.</Text>
            </Flex>

            <Flex justify="center" style={{marginBottom: "30px"}}>
                <Button type="ghost" style={{marginTop: "20px", fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>CARGAR</Button>
                <Button type="ghost" style={{marginTop: "20px", fontWeight: "600", color: "gray", border: "1.8px solid gray", marginLeft: "20px"}}>CANCELAR</Button>
            </Flex>

        </Flex>
    )
}
