import React, { useState } from 'react'
// Ant Design
import { Flex, Input, Typography, Button, Tooltip } from 'antd'
// Icons
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
// Configuraciones
import { getCongif } from '../../../config';

const { Title } = Typography;

export const Formulario = () => {

    const [arrVar, setArrVar] = useState([])
    // Para agregar otra variable
    const [numero, setNumero] = useState(2);
    const addVar = () => {
        setNumero(numero + 1)
        const obj = {nombre: `Variable ${numero}`}
        setArrVar(prevArrVar => [...prevArrVar, obj])
    }

    // Para eliminar una variable
    const deleteVar = () => {
        const nuevoArr = arrVar.slice(0, arrVar.length - 1);
        setArrVar(nuevoArr);
        setNumero(numero - 1);
    }

    return (
        <Flex vertical align="center" style={{width: "100%"}}>
            <Flex vertical style={{width: "60%"}}>
                <Title level={4} style={{fontWeight: "400"}}>Template Id</Title>
                <Input placeholder="00000000000000" size="large" />
            </Flex>
            <Flex vertical style={{width: "60%", marginTop: "20px"}}>
                <Title level={4} style={{fontWeight: "400"}}>Número</Title>
                <Input placeholder="5555555555" size="large" />
            </Flex>
            <Flex vertical style={{width: "60%", marginTop: "20px"}}>
                <Title level={4} style={{fontWeight: "400"}}>Variables</Title>
                <Input placeholder="Variable 1" size="large" />
            </Flex>
            {arrVar.map((item) => (
                <Flex key={item.nombre}  vertical style={{width: "60%", marginTop: "20px"}}>
                    <Input placeholder={item.nombre} size="large" suffix={<Tooltip title="Eliminar Variable"><MinusOutlined onClick={deleteVar} style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                </Flex>
            ))}
            {numero < 4 &&
                <Flex justify="flex-end" style={{width: "60%", marginTop: "20px"}}>
                    <Button onClick={addVar} type="ghost" icon={<PlusOutlined />} style={{color: getCongif("colorSecundario"), border: `1px solid ${getCongif("colorSecundario")}`}}>Agregar Variable</Button>
                </Flex>
            }
            <Flex>
                <Button type="ghost" style={{color: getCongif("colorPrimario"), border: `1px solid ${getCongif("colorPrimario")}`, marginTop: "10px"}}>ENVIAR</Button>
            </Flex>
        </Flex>
    )
}
