import React, { useEffect, useState } from 'react'
// React Router
import { useNavigate } from 'react-router-dom'
// Ant Design
import { Flex, Image, Button, Switch } from 'antd'
// Icons
import { LogoutOutlined } from '@ant-design/icons'
// Assets
import Logo from '../assets/logo_murphy.png'
// Configuraciones
import { getCongif } from '../../config'
// i18
import { useTranslation } from 'react-i18next'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../config'

export const Navbar = () => {

    const navigate = useNavigate()
    const onLoguot = async() => {
        try {
            const res = await axios.get(API_URL + "/auth/logout",{
                withCredentials: true
            
            })
            if(res.status === 201) {
                navigate("/login")
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // Para el idioma
    const { t, i18n } = useTranslation(["translation"]);
    
    const handleChange = (value) => {
        if(value) {
            i18n.changeLanguage("es"); 
            localStorage.setItem("lang", "true");
        }
        else {
            i18n.changeLanguage("en");
            localStorage.setItem("lang", "false");
        }
    };

    return (
        <nav style={{width: "100%", zIndex: "10"}}>
            <Flex justify="space-between" align="center" style={{position: "relative", overflow: "hidden", backgroundColor: getCongif("fondo"), height: "80px", padding: "0 20px"}}>
                <Image style={{minWidth: "100px", maxWidth: "300px", maxHeight: "80px"}} src={getCongif("logo")==="" ? Logo : getCongif("logo")} />

                <Flex gap={12} align="center">
                    <Switch className="custom-switch" onChange={handleChange} checkedChildren="ES" unCheckedChildren="EN" defaultValue={localStorage.getItem("lang")==="true" ? true : false} />
                    <Button onClick={onLoguot} type="ghost" size="large" icon={<LogoutOutlined />} style={{color: "#fff", border: "1.5px solid #fff", padding: "2px 10px", fontWeight: "400"}}>{t("logout")}</Button>
                </Flex>
            </Flex>

            <div style={{background: getCongif("degradado"), height: "3px"}}></div>
        </nav>
    )
}
 