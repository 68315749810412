import React, { useEffect, useState } from 'react'
// Ant Design
import { Table, Tag } from 'antd'
import { SyncOutlined, CheckCircleOutlined, ClockCircleOutlined, CommentOutlined, UserSwitchOutlined, CloseCircleOutlined } from '@ant-design/icons'
// Moment
import moment from 'moment'
import 'moment/locale/es'
// i18
import { useTranslation } from 'react-i18next'
// Styles
import '../../styles/Tabla.css'

export const Tabla = ({dataApi, total, page, pageSize, setPage, setPageSize, cargando, sizeTemplates, validQueueId, cabecerasAttr}) => {
    // Para el idioma
    const { t, i18n } = useTranslation(["translation"]);

    const generateTemplateVarColumns = (num) => {
        const columns = [];
        for (let i = 1; i <= num; i++) {
            columns.push({
                title: <p style={{fontWeight: "700", fontSize: "15px"}}>{`Template_Var${i}`}</p>,
                dataIndex: `var${i}`,
                align: 'center',
                width: 250
            });
        }
        return columns;
    }

    const generateAttrVarColumns = () => {
        const columns = [];
        if (cabecerasAttr && cabecerasAttr.length > 0) {
            cabecerasAttr.forEach((item) => {
                columns.push({
                    title: <p style={{fontWeight: "700", fontSize: "15px"}}>{item}</p>,
                    dataIndex: item,
                    align: 'center',
                    width: 200
                });
            });
        }
        return columns;
    }

    const generateQueueIdColumn = () => {
        const columns = [];
        if (validQueueId) {
            columns.push({
                title: <p style={{fontWeight: "700", fontSize: "15px"}}>QueueId</p>,
                dataIndex: 'queueid',
                align: 'center',
                width: 200
            });
        }
        return columns;
    }
    
    const columns = [
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>UUID</p>,
            dataIndex: 'uuid',
            align: 'center',
            width: 200
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_phone")}</p>,
            dataIndex: 'telefono',
            align: 'center',
            fixed: 'left',
            width: 140
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_name_file")}</p>,
            dataIndex: 'nombre',
            align: 'center',
            fixed: 'left',
            width: 250
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_date_programmed")}</p>,
            dataIndex: 'fecha',
            align: 'center',
            width: 250,
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_date_termination")}</p>,
            dataIndex: 'fechaterminacion',
            align: 'center',
            width: 250,
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>Template Id</p>,
            dataIndex: 'templateid',
            align: 'center',
            width: 360
        },
        ...generateTemplateVarColumns(sizeTemplates),
        ...generateQueueIdColumn(),
        ...generateAttrVarColumns(),
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("lastevent")}</p>,
            dataIndex: 'fechamodificacion',
            align: 'center',
            width: 250,
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_status")}</p>,
            dataIndex: 'estatus',
            align: 'center',
            fixed: 'right',
            width: 120
        },
    ];
    // console.log(dataApi)
    const [data, setData] = useState([]);
    useEffect(() => {
        const arr = [];
        dataApi.map((item) => {
            const attr = item?.attributes;

            arr.push({
                key: item.id,
                uuid: item.contactId,
                telefono: item.phone,
                nombre: item.name,
                fecha: formatearFechaEnEspanol(item.initDatetime),
                fechaterminacion: formatearFechaEnEspanol(item.endDatetime),
                templateid: item.templateId,
                var1: item.templateVars[1],
                var2: item.templateVars[2],
                var3: item.templateVars[3],
                var4: item.templateVars[4],
                var5: item.templateVars[5],
                var6: item.templateVars[6],
                var7: item.templateVars[7],
                var8: item.templateVars[8],
                var9: item.templateVars[9],
                var10: item.templateVars[10],
                queueid: item.queueId,
                ...attr,
                fechamodificacion: formatDatestate(item.states),
                estatus: statusStyle(item.states)
            })
        })
        setData(arr);
    }, [dataApi])

    console.log(dataApi)
    const statusStyle = (status) => {

        const keys = Object.keys(status);
        const length = keys.length;
        if(length > 1) {
            delete status['SCHEDULED'];
        } 

        const entries = Object.entries(status);
        entries.sort((a, b) => moment(b[1], 'DD-MM-YYYY HH:mm:ss').valueOf() - moment(a[1], 'DD-MM-YYYY HH:mm:ss').valueOf());
        const valor = entries[0][0];

        if(valor === "SCHEDULED") {
            return <Tag icon={<SyncOutlined spin />} color="processing">{t("table_scheduled")}</Tag>
        }
        else if (valor === 'SENT') {
            return <Tag icon={<CheckCircleOutlined />} color="success">{t("table_sent")}</Tag>
        }
        else if (valor === 'ANSWERED') {
            return <Tag icon={<CommentOutlined />} color="gold">{t("table_answered")}</Tag>
        }
        else if (valor === 'AGENT') {
            return <Tag icon={<UserSwitchOutlined />} color="default">{t("table_agent")}</Tag>
        }
        else if (valor === 'EXPIRED') {
            return <Tag icon={<ClockCircleOutlined />} color="orange">{t("table_expired")}</Tag>
        }
        else if (valor === 'FAILED') {
            return <Tag icon={<CloseCircleOutlined />} color="red">{t("table_error")}</Tag>
        }
    }

     // Formatear fecha
     const formatearFechaEnEspanol = (fecha) => {
        if(i18n.language === 'en') {
            moment.locale('en');
        }
        else {
            moment.locale('es');
        }
        const fechaDos = moment(fecha, 'DD-MM-YYYY HH:mm:ss').format('MMMM Do YYYY, h:mm:ss a');
        const fechaMejorada = fechaDos.replace('º', '');
        return fechaMejorada;
    };

    const formatDatestate = (date) => {
        const keys = Object.keys(date);
        const length = keys.length;
        if(length > 1) {
            delete date['SCHEDULED'];
        }
        
        const valores = Object.values(date);
        
        if (i18n.language === 'en') {
            moment.locale('en');
        } else {
            moment.locale('es');
        }
    
        const fechasMoment = valores.map(fecha => moment(fecha, 'DD-MM-YYYY HH:mm:ss'));
        const fechaMasReciente = moment.max(fechasMoment);    
        const fechaFormateada = fechaMasReciente.format('MMMM Do YYYY, h:mm:ss a');
        const fechaMejorada = fechaFormateada.replace('º', '');
    
        return fechaMejorada;
    };
    
    return (
        <div style={{marginTop: "20px", padding: "0 40px"}}>
            <Table
                columns={columns}
                dataSource={data}
                loading={cargando}
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                    position: ['bottomCenter'],
                    onChange: (page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize);
                    },
                }}
                scroll={{
                    x: 2000,
                    y: '80vh',
                }}
            />
        </div>
    )
}
