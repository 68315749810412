import React from 'react'
// Ant Design
import { Button, Flex, Tooltip } from 'antd'
// Icons
import { ToTopOutlined, PlusOutlined } from '@ant-design/icons'
// Configuraciones
import { getCongif } from '../../../config'
// React Router
import { useNavigate } from 'react-router-dom'
// i18
import { useTranslation } from 'react-i18next'

export const Botones = () => {
    const navigate = useNavigate();

    // Para el idioma
    const { t } = useTranslation(["translation"]);

    return (
        <div style={{position: "fixed", bottom: "0", right: "0", width: "50px", height: "100vh"}}>
            <Flex vertical justify="center" align="center" style={{width: "100%", height: "100%"}}>

                <Tooltip title={t("btn_add_campaign")} placement="left" color={getCongif("colorPrimario")}>
                    <Button onClick={() => navigate("/agregarCampaña")} type="ghost" size="large" shape="circle" style={{color: getCongif("colorPrimario"), border: `2px solid ${getCongif("colorPrimario")}`, marginBottom: "20px"}} icon={<PlusOutlined />} />
                </Tooltip>

                <Tooltip title={t("btn_upload_file")} placement="left" color={getCongif("colorPrimario")}>
                    <Button onClick={() => navigate("/subirArchivos")} type="ghost" size="large" shape="circle" style={{color: getCongif("colorPrimario"), border: `2px solid ${getCongif("colorPrimario")}`}} icon={<ToTopOutlined />} />
                </Tooltip>
            
            </Flex>
        </div>
    )
}
