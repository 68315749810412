import React from 'react'
// Ant Design
import { Button, Tooltip } from 'antd'
// Icons
import { LeftOutlined } from '@ant-design/icons'
// Configuraciones
import { getCongif } from '../../config'
// React Router
import { useNavigate } from 'react-router-dom'
// i18
import { useTranslation } from 'react-i18next'

export const BtnRegresar = () => {
    const navigate = useNavigate();

    // Para el idioma
    const { t } = useTranslation(["translation"]);

    return (
        <div style={{position: "fixed", bottom: "10px", left: "10px", zIndex: 100}}>
            <Tooltip title={t("btn_back")} placement="top" color={getCongif("colorPrimario")}>
                <Button onClick={() => navigate("/campañas")} type="ghost" size="large" shape="circle" style={{color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}} icon={<LeftOutlined />} />
            </Tooltip>
        </div>
    )
}
