import { useEffect } from 'react';
// Configuraciones
import { getCongif } from './config';
// Pages
import { Login } from './components/pages/Login';
import { Campañas } from './components/pages/Campañas';
import { Detalles } from './components/pages/Detalles';
import { Archivos } from './components/pages/Archivos';
import { AgregarCampaña } from './components/pages/AgregarCampaña';
import { AgregarTemplate } from './components/pages/AgregarTemplate';
import { Lista } from './components/pages/Lista';
import { CrearTemplate } from './components/pages/CrearTemplate';
// React Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// i18
import { useTranslation } from 'react-i18next'

function App() {
    document.documentElement.style.setProperty('--color-primario', getCongif('colorPrimario'));

    // Para el idioma
    const { i18n } = useTranslation(["translation"]);
    useEffect(() => {
        if(localStorage.getItem("lang")==="true") {
            i18n.changeLanguage("es");
        }
        else {
            i18n.changeLanguage("en");
        }
    }, [])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/campañas" element={<Campañas />} />
                <Route path="/detalle/:nombre" element={<Detalles />} />
                <Route path="/subirArchivos" element={<Archivos />} />
                <Route path="/agregarCampaña" element={<AgregarCampaña />} />
                {/* <Route path="/agregarTemplate" element={<AgregarTemplate />} />
                <Route path="/lista" element={<Lista />} />
                <Route path="/crearTemplate" element={<CrearTemplate />} /> */}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
