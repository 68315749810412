import React, { useEffect, useState } from 'react'
// i18
import { useTranslation } from 'react-i18next'
// Ant Design
import { Badge, Flex, Typography } from 'antd'
// Configuraciones
import { getCongif } from '../../../config'

const { Text } = Typography

export const Widgets = () => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    const [status, setStatus] = useState({})
    useEffect(() => {
        const myObjectString = localStorage.getItem('estatus');
        const myObject = JSON.parse(myObjectString);
        setStatus(myObject);
    }, [])

    return (
        <Flex wrap="wrap" justify="space-between" style={{padding: "0 20px"}}>
            <Badge.Ribbon text={t("scheduled")} style={{marginRight: "10px"}} color={getCongif("colorPrimario")}>
                <Flex align='center' style={{width: "150px", height: "34px", paddingLeft: "10px", backgroundColor: "#E6F4FF", border: "1px solid #C0E1FF", marginTop: "10px", marginRight: "10px", marginRight: "10px"}}>
                    <Text style={{fontSize: "16px", color: "#0958D9"}}>{status.scheduled}</Text>
                </Flex>
            </Badge.Ribbon>
            <Badge.Ribbon text={t("sent")} style={{marginRight: "10px"}} color={getCongif("colorPrimario")}>
                <Flex align='center' style={{width: "150px", height: "34px", paddingLeft: "10px", backgroundColor: "#F6FFED", border: "1px solid #B7EB8F", marginTop: "10px", marginRight: "10px"}}>
                    <Text style={{fontSize: "16px", color: "#389E0D"}}>{status.sent}</Text>
                </Flex>
            </Badge.Ribbon>
            <Badge.Ribbon text={t("answered")} style={{marginRight: "10px"}} color={getCongif("colorPrimario")}>
                <Flex align='center' style={{width: "150px", height: "34px", paddingLeft: "10px", backgroundColor: "#FFFBE6", border: "1px solid #FFE58F", marginTop: "10px", marginRight: "10px"}}>
                    <Text style={{fontSize: "16px", color: "#DC9D2F"}}>{status.answered}</Text>
                </Flex>
            </Badge.Ribbon>
            <Badge.Ribbon text={t("agent")} style={{marginRight: "10px"}} color={getCongif("colorPrimario")}>
                <Flex align='center' style={{width: "150px", height: "34px", paddingLeft: "10px", backgroundColor: "#FAFAFA", border: "1px solid #D9D9D9", marginTop: "10px", marginRight: "10px"}}>
                    <Text style={{fontSize: "16px", color: "#1E1E1E"}}>{status.agent}</Text>
                </Flex>
            </Badge.Ribbon>
            <Badge.Ribbon text={t("expired")} style={{marginRight: "10px"}} color={getCongif("colorPrimario")}>
                <Flex align='center' style={{width: "150px", height: "34px", paddingLeft: "10px", backgroundColor: "#FFF7E6", border: "1px solid #FFD591", marginTop: "10px", marginRight: "10px"}}>
                    <Text style={{fontSize: "16px", color: "#D46B08"}}>{status.expired}</Text>
                </Flex>
            </Badge.Ribbon>
            <Badge.Ribbon text={t("errors")} style={{marginRight: "10px"}} color={getCongif("colorPrimario")}>
                <Flex align='center' style={{width: "150px", height: "34px", paddingLeft: "10px", backgroundColor: "#FFF1F0", border: "1px solid #FF8E8E", marginTop: "10px", marginRight: "10px"}}>
                    <Text style={{fontSize: "16px", color: "#CF1322"}}>{status.failed}</Text>
                </Flex>
            </Badge.Ribbon>
        </Flex>
    )
}
