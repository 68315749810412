import React, { useEffect, useState } from 'react'
// Layout
import { Navbar } from '../layout/Navbar'
// React Router
import { useParams, useNavigate } from 'react-router-dom'
// Ant Design
import { Flex, Typography, Button, Tooltip } from 'antd'
// Ant Design Icons
import { RetweetOutlined } from '@ant-design/icons'
// Fragments
import { BreadCrumb } from '../fragments/Detalles/BreadCrumb'
import { Buscador } from '../fragments/Detalles/Buscador'
import { FiltroEstatus } from '../fragments/Detalles/FiltroEstatus'
import { Tabla } from '../fragments/Detalles/Tabla'
import { BtnRegresar } from '../layout/BtnRegresar'
import { Descargar } from '../fragments/Detalles/Descargar'
import { Agregar } from '../fragments/Detalles/Agregar'
import { Widgets } from '../fragments/Detalles/Widgets'
// Configuraciones
import { getCongif } from '../../config'
// i18
import { useTranslation } from 'react-i18next'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../config'

const { Text } = Typography

export const Detalles = () => {

    // Para el idioma
    const { i18n } = useTranslation(["translation"]);

    // Traer el id de la URL
    const { nombre } = useParams()

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [cargando, setCargando] = useState(true);
    const [sizeTemplates, setSizeTemplates] = useState(0);
    const [cabecerasAttr, setCabecerasAttr] = useState([]);
    const [validQueueId, setValidQueueId] = useState(false);
    const [phone, setPhone] = useState(null);
    const [name, setName] = useState(null);
    const [estatus, setEstatus] = useState(null);
    const navigate = useNavigate()

    const apiDetalle = async() => {
        setCargando(true)
        try {
            const res = await axios.get(API_URL + `/campaigns/detail/${nombre}`, {
                withCredentials: true,
                params: {
                    page: page,
                    perPage: pageSize,
                    phone: phone,
                    name: name,
                    stateBy: estatus,
                }
            });
            setData(res.data.entities)
            setTotal(res.data.total)
            setCargando(false)

            // Tamaño de los templates
            const arr = []
            res.data.entities.forEach(item => {
                const tem = item.templateVars
                const size = Object.keys(tem).length
                arr.push(size)
            })
            const sizeFinal = Math.max(...arr)
            setSizeTemplates(sizeFinal)

            // Para sacar las cabeceras de los attributes
            const arr3 = []
            res.data.entities.forEach(item => {
                const attr = item.attributes
                arr3.push(Object.keys(attr))
            })
            const objetoMasGrande = arr3.reduce((max, obj) => {
                return Object.keys(obj).length > Object.keys(max).length ? obj : max;
            }, {});
            setCabecerasAttr(objetoMasGrande)

            const validQueueId = res.data.entities.some(item => item.queueId !== undefined);
            setValidQueueId(validQueueId)
        }
        catch (error) {
            setCargando(false)
        }
    }

    const isLogin = async() => {
        try {
            await axios.get(API_URL + "/auth/checkLogin",{
                withCredentials: true
            
            })
        }
        catch (error) {
            if(error.response.status === 401) {
                navigate("/login")
            }
        }
    }

    useEffect(() => {
        apiDetalle()
        isLogin()
    }, [page, pageSize, phone, name, estatus, i18n.language])

    return (
        <section style={{position: "relative"}}>
            <Navbar />

            <Flex style={{width: "100%"}}>
                <Flex vertical style={{padding: "10px", width: "50%"}}>
                    <Flex justify="space-between" align="center">
                        <BreadCrumb nombre={nombre} />
                        <Text style={{fontSize: "20px", color: getCongif("colorPrimario"), fontWeight: "600"}}>Total: {total}</Text>
                    </Flex>
                    <Flex>
                        <Buscador setPhone={setPhone} setName={setName} />
                        <FiltroEstatus setEstatus={setEstatus} />
                        <Tooltip title="Reset">
                            <Button onClick={apiDetalle} size="large" shape="circle" icon={<RetweetOutlined />} style={{marginLeft: "5px", alignSelf: "flex-end"}} />
                        </Tooltip>
                    </Flex>
                </Flex>

                <Flex vertical align="end" style={{width: "50%"}}>
                    <Widgets />
                </Flex>
            </Flex>

            <Tabla dataApi={data} total={total} page={page} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize} cargando={cargando} sizeTemplates={sizeTemplates} validQueueId={validQueueId} cabecerasAttr={cabecerasAttr} />

            <BtnRegresar />
            <Descargar nombre={nombre} />
            {/* <Agregar /> */}
                        
        </section>
    )
}
