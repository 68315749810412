import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

// Inicialización de i18next sin recursos
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'es', // language to use
  resources: {}, // Inicialmente sin recursos
});

// Función para cargar los archivos JSON de traducción
async function loadTranslations() {
  try {
    const response_es = await fetch('/locales/es/translation.json');
    const global_es = await response_es.json();
    
    const response_en = await fetch('/locales/en/translation.json');
    const global_en = await response_en.json();

    // Actualiza los recursos de i18next con los archivos JSON cargados
    i18next.init({
      interpolation: { escapeValue: false },
      lng: 'es',
      resources: {
        es: { translation: global_es },
        en: { translation: global_en },
      },
    });
  } catch (error) {
    console.error('Error al cargar los archivos JSON de traducción:', error);
  }
}

// Carga los archivos JSON de traducción antes de renderizar la aplicación
loadTranslations().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  );
});