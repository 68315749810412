import React, { useState } from 'react'
// Ant Design
import { Button, Tooltip, Modal, message, Flex, Spin, Typography } from 'antd'
// Icons
import { DownloadOutlined } from '@ant-design/icons'
// i18
import { useTranslation } from 'react-i18next'
// Axios
import axios from 'axios'
// Config
import {API_URL, getCongif} from '../../../config'

const { Text } = Typography;

export const Descargar = ({nombre}) => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    // Para modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    // Alerta de error
    const [messageApi, contextHolder] = message.useMessage();
    const Error = () => {
        messageApi.open({
            type: 'error',
            content: t("error_download"),
        });
    };

    // Al dar en descargar
    const descargar = async() => {
        setIsModalOpen(true);
        try {
            const res = await axios.get(API_URL + `/campaigns/download/${nombre}`, {
                withCredentials: true,
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${nombre}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            if(res.status === 200) {
                setIsModalOpen(false);
            }
        } 
        catch (error) {
            setIsModalOpen(false);
            Error();
        }
    }

    return (
        <>
            <div style={{position: "absolute", bottom: "10px", right: "10px", zIndex: 100}}>
                <Tooltip title={t("btn_download")} placement="top" color={getCongif("colorPrimario")}>
                    <Button onClick={descargar} type="ghost" size="large" shape="circle" style={{color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}} icon={<DownloadOutlined />} />
                </Tooltip>
            </div>
        
            <Modal title={<div style={{fontSize: "22px", textAlign: "center", color: getCongif("colorPrimario")}}>{t("download_file")}</div>} open={isModalOpen} footer={null} closable={false}>
                <Flex vertical align="center" style={{marginTop: "20px"}}>
                    <Spin size="large" />
                    <Text style={{marginTop: "10px"}}>{t("wait_moment")}</Text>
                </Flex>
            </Modal>

            {contextHolder}
        </>
    )
}
