import React, { useEffect, useState } from 'react'
// Ant Design
import { Flex, Button, Table, Tooltip, Typography, Modal, Upload, message, Popconfirm } from 'antd'
// Icons
import { DeleteOutlined, VideoCameraOutlined, SoundOutlined, FileOutlined, FolderOpenOutlined, PictureOutlined, InboxOutlined, CheckCircleOutlined } from '@ant-design/icons'
// Moment js
import moment from 'moment'
import 'moment/locale/es'
// i18
import { useTranslation } from 'react-i18next'
// Configuraciones
import { getCongif } from '../../../config'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../../config'


export const Tabla = ({dataApi, cargando, recargar, setRecargar}) => {
    // Para el idioma
    const { t, i18n } = useTranslation(["translation"]);

    const { Link } = Typography;
    
    const columns = [
        {
            title: t("table_name_file"),
            dataIndex: 'nombre',
        },
        {
            title: t("table_size_file"),
            dataIndex: 'tamaño',
        },
        {
            title: t("table_last_modified"),
            dataIndex: 'fecha',
        },
        {
            title: t("table_direction"),
            dataIndex: 'direccion',
        },
        {
            title: '',
            dataIndex: 'eliminar',
            width: 60
        },
    ];

    // Funcion para saber que icono poner
    const iconShow = (name) => {
        if (name.includes(".mp4")) {
            return <VideoCameraOutlined style={{marginRight: "5px"}} />
        } 
        else if (name.includes(".mp3")) {
            return <SoundOutlined style={{marginRight: "5px"}} />
        } 
        else if (name.includes(".png") || name.includes(".jpg") || name.includes(".jpeg") || name.includes(".gif")) {
            return <PictureOutlined style={{marginRight: "5px"}} />
        }
        else if(name.includes(".pdf") || name.includes(".doc") || name.includes(".docx") || name.includes(".xls") || name.includes(".xlsx") || name.includes(".ppt") || name.includes(".pptx") || name.includes(".txt") || name.includes(".csv") || name.includes(".xml") || name.includes(".json") || name.includes(".zip") || name.includes(".rar") || name.includes(".7z") || name.includes(".tar") || name.includes(".gz") || name.includes(".bz2") || name.includes(".xz") || name.includes(".iso") || name.includes(".img") || name.includes(".apk") || name.includes(".exe") || name.includes(".msi") || name.includes(".deb") || name.includes(".rpm") || name.includes(".dmg") || name.includes(".pkg") || name.includes(".app") || name.includes(".bat") || name.includes(".sh") || name.includes(".cmd") || name.includes(".ps1") || name.includes(".vbs") || name.includes(".jar") || name.includes(".war") || name.includes(".ear") || name.includes(".class") || name.includes(".py")) {
            return <FileOutlined style={{marginRight: "5px"}} />
        }
        else {
            return <FolderOpenOutlined style={{marginRight: "5px"}} />
        }
    }

    // Para data de tabla
    const [data, setData] = useState([]);
    useEffect(() => {
        const arr = [];
        dataApi?.map((item, index) => (
            arr.push({
                key: item.ETag + index,
                nombre: (
                    <Flex>
                        {iconShow(item.Key)}
                        {item.Key}
                    </Flex>
                ),
                tamaño: `${item.Size} bytes`,
                fecha: formatearFechaEnEspanol(item.LastModified), 
                direccion: <Link href={item.url} target="_blank">{item.url}</Link>,
                eliminar: (
                    <Tooltip title={t("btn_delete")} color="#FF0000">
                        <Popconfirm
                            title={t("alert_title_file")}
                            description={t("alert_description_file")}
                            onConfirm={() => onDelete(item)}
                            okText={t("text_confirm")}
                            cancelText="No"
                            zIndex={999999}
                        >
                            <Button type="ghost" shape="circle" style={{color: "#FF0000", border: "1px solid #FF0000"}} icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                )
            })
        ))
        setData(arr);
    }, [dataApi])

    // Para dar formato a las fechas
    const formatearFechaEnEspanol = (fecha) => {
        if(i18n.language === "en") {
            moment.locale('en');
        }
        else {
            moment.locale('es');
        }
        const fechaDos = moment(fecha, 'DD-MM-YYYY HH:mm:ss').format('MMMM Do YYYY, h:mm:ss a');
        const fechaMejorada = fechaDos.replace('º', '');
        return fechaMejorada;
    };

    // Para eliminar un archivo
    const onDelete = async(id) => {
        let idSend = id.Key;
        const idCamp = idSend.replace("media/", "")

        try {
            const res = await axios.delete(API_URL + `/mediafiles/${idCamp}`,{
                withCredentials: true,
            
            });
            if(res.status === 200) {
                setRecargar(!recargar);
                message.success(t("message_delete_file"));
            }
            else {
                message.error(t("mesage_error_delete_file"));
            }
        } catch (error) {
            message.error(t("mesage_error_delete_file"));
        }
    }

    // Para modal
    const { Dragger } = Upload;
    const [fileList, setFileList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cargandoMedia, setCargandoMedia] = useState(false);

    const handleOk = async() => {
        setCargandoMedia(true);
        if(fileList.length === 0) {
            message.error(t("message_upload_file"));
            setCargandoMedia(false);
        }
        else {
            try {
                const res = await axios.post(API_URL + "/mediafiles", fileList, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if(res.status === 200) {
                    setCargandoMedia(false);
                    setRecargar(!recargar);
                    message.success(t("message_add_file"));
                    setFileList([]);
    
                    // Eliminar archivo cargado al enviar mensaje
                    const formData = new FormData();
                    fileList.forEach((file) => {
                        formData.append('files[]', file);
                    });
                }
                else {
                    setCargandoMedia(false);
                    message.error(t("message_error_upload"));
                    setFileList([]);
    
                    // Eliminar archivo cargado al enviar mensaje
                    const formData = new FormData();
                    fileList.forEach((file) => {
                        formData.append('files[]', file);
                    });
                }
            } catch (error) {
                if(error.response.data.errorCode==="FILE_EXISTS") {
                    message.error(t("message_error_FILE_EXISTS"));
                }
                setFileList([]);
                setCargandoMedia(false);
    
                // Eliminar archivo cargado al enviar mensaje
                const formData = new FormData();
                fileList.forEach((file) => {
                    formData.append('files[]', file);
                });
            }
            setIsModalOpen(false);
        }
    };

    const props = {
        name: 'file',
        multiple: false,
        accept: 'image/*,video/*,audio/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        onRemove: file => {
            setFileList(fileList.filter(item => item.uid !== file.uid));
        },
        fileList,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    
    return (
        <>
            <div style={{padding: "0 80px"}}>
                <Flex justify="flex-end">
                    <Button onClick={() => setIsModalOpen(true)} type="ghost" style={{marginTop: "10px", fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>{t("btn_upload")}</Button>
                </Flex>

                <Flex style={{width: "100%", marginTop: "10px"}}>
                    <Table
                        pagination={{
                            pageSize: 50,
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                        }}
                        scroll={{
                            y: '80vh',
                        }}
                        style={{width: "100%"}}
                        columns={columns}
                        dataSource={data}
                        loading={cargando ? true : false}
                    />
                </Flex>
            </div>
        
            <Modal style={{maxHeight: '300px'}}  title={t("text_upload_file")} okText={t("btn_text_ok")} cancelText={t("btn_text_cancel")} open={isModalOpen} onOk={handleOk} onCancel={() => setIsModalOpen(false)} confirmLoading={cargandoMedia}>
                <Dragger style={{maxHeight: '300px'}} {...props}  maxCount={1}>
                    <p className="ant-upload-drag-icon">
                        {fileList.length > 0 
                            ? <CheckCircleOutlined style={{color: "#2CA438"}} />
                            : <InboxOutlined style={{color: getCongif("colorPrimario")}} />
                        }
                    </p>
                    <p className="ant-upload-text">{t("header_title_upload")}</p>
                    <p className="ant-upload-hint">{t("text_primary_upload")}</p>
                    <p className="ant-upload-hint">{t("text_secondary_upload")}</p>
                </Dragger>
            </Modal>
        </>
    )
}
