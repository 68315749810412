import React, { useEffect } from 'react'
// React Router
import { useNavigate } from 'react-router-dom'
// Layout
import { Navbar } from '../layout/Navbar'
// Fragments
import { BreadCrumb } from '../fragments/AgregarCampaña/BreadCrumb'
import { Form } from '../fragments/AgregarCampaña/Form'
import { BtnRegresar } from '../layout/BtnRegresar'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../config'

export const AgregarCampaña = () => {

    const navigate = useNavigate()
    const isLogin = async() => {
        try {
            await axios.get(API_URL + "/auth/checkLogin",{
                withCredentials: true
            
            })
        }
        catch (error) {
            if(error.response.status === 401) {
                navigate("/login")
            }
        }
    }
    useEffect(() => {
        isLogin()
    }, [])

    return (
        <section>
            <Navbar />
            <BreadCrumb />
            <Form />
            <BtnRegresar />
        </section>
    )
}
