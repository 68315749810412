import React, { useState } from 'react'
// i18
import { useTranslation } from 'react-i18next'
// Ant Design
import { Flex, Input } from 'antd'
const { Search } = Input;

export const Buscador = ({setName}) => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    // Para filtro de busqueda
    const [filtro, setFiltro] = useState("")

    // Para cuendo el input este vacio
    const handleInputChange = (e) => {
        setFiltro(e.target.value);
        if (e.target.value === '') {
            setName('');
        }
    }
    
    return (
        <Flex style={{width: "50%", zIndex: "10"}} justify="flex-end">
            <Search onSearch={() => setName(filtro)} onChange={handleInputChange} placeholder={t("filter_name_campaign")} allowClear size="large" style={{ width: "70%" }} />
        </Flex>
    )
}
