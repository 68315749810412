import React, { useState } from 'react'
// i18
import { useTranslation } from 'react-i18next'
// Ant Design
import { Input } from 'antd'
const { Search } = Input;

export const Buscador = ({setPhone, setName}) => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    const [filtro, setFiltro] = useState("")

    // Para cuendo el input este vacio
    const handleInputChange = (e) => {
        setFiltro(e.target.value);
        if (e.target.value === '') {
            setPhone('');
            setName('');
        }
    }

    // Filtrar por nombre o teléfono
    const filtrar = () => {
        if (/^\d/.test(filtro) === true) {
            setPhone(filtro)
        }
        else if(/^[a-zA-Z]/.test(filtro) === true) {
            setName(filtro)
        }
    }

    return (
        <Search onSearch={filtrar} onChange={handleInputChange} placeholder={t("filter_name_and_phone")} allowClear size="large" style={{ width: "80%", marginTop: "10px", marginRight: "10px" }} />
    )
}
