import React, { useEffect, useState } from 'react'
// React Router
import { useNavigate } from 'react-router-dom'
// Ant Design
import { Col, Row, Image, Flex, Input, Checkbox, Button, Typography, message } from 'antd'
// Icons
import { UserOutlined, LockOutlined } from '@ant-design/icons'
// Assets
import Logo from '../assets/logo_murphy.png'
// Configuraciones
import { getCongif } from '../../config'
// i18
import { useTranslation } from 'react-i18next'
// CriptoJs
import CryptoJS from 'crypto-js'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../config'

export const Login = () => {
    const [messageApi, contextHolder] = message.useMessage();

    function FormLogin() {
        // Constantes a ocupar
        const { Title } = Typography;
        const navigate = useNavigate();
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
        const [errorEmail, setErrorEmail] = useState(false);
        const [errorPass, setErrorPass] = useState(false);
        const [cargando, setCargando] = useState(false);
        const [check, setCheck] = useState(false);

        // Para el mensaje de error
        const Mensaje = () => {
            messageApi.open({
                type: 'error',
                content: 'Usuario o contraseña incorrectos',
                duration: 6,
            });
        };

        // Función para iniciar sesión
        const onLogin = async() => {
            if(email === "" && password === "") {
                setErrorEmail(true);
                setErrorPass(true);
            }
            else if(email === "") {
                setErrorEmail(true);
            }
            else if(password === "") {
                setErrorPass(true);
            }
            else {
                setCargando(true);
                const userdata = {email: email, pass: password}
    
                try {
                    const res = await axios.post(API_URL + "/auth/login", userdata, {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                    if(res.status===201) {
                        navigate("/campañas");
                        setCargando(false);
                        if(check) {
                            const encryPass = CryptoJS.AES.encrypt(password, "@murphy").toString();
                            localStorage.setItem("user", email);
                            localStorage.setItem("pass", encryPass);
                        }
                        else {
                            localStorage.removeItem("user");
                            localStorage.removeItem("pass");
                        }
                    }
                    else {
                        Mensaje();
                        setCargando(false);
                    }
                } 
                catch (error) {
                    Mensaje();
                    setCargando(false);
                }
            }
        }

        // Para checar si el recuerdame esta activo
        useEffect(() => {
            const user = localStorage.getItem("user");
            const pass = localStorage.getItem("pass");

            if(user !== null) {
                const bytes  = CryptoJS.AES.decrypt(pass, "@murphy");
                const originalPass = bytes.toString(CryptoJS.enc.Utf8);

                setEmail(user);
                setPassword(originalPass);
                setCheck(true);
            }
        }, [])

        // Para el idioma
        const { t } = useTranslation(["translation"]);

        return (
            <>
                <div style={{position: "absolute", width: ".1px", height: "100%", left: "0", display: "flex", alignItems: "center"}}>
                    <div style={{width: "100%", height: "70%", backgroundColor: "#fff"}}></div>
                </div>

                <Flex align="center" vertical style={{ width: "70%"}}>
                    <Title level={3} style={{color: "#fff", fontWeight: "400", textAlign: "center", marginBottom: "15px"}}>{t("title_login")}</Title>

                    <Input 
                        onChange={(e) => {setEmail(e.target.value); setErrorEmail(false)}} 
                        value={email} 
                        status={errorEmail && "error"} 
                        size="large" 
                        placeholder={t("input_user")} 
                        prefix={<UserOutlined />} 
                        style={{marginBottom: "15px"}}
                    />
                    <Input.Password 
                        onChange={(e) => {setPassword(e.target.value); setErrorPass(false)}} 
                        value={password} 
                        status={errorPass && "error"} 
                        size="large" 
                        placeholder={t("input_password")} 
                        prefix={<LockOutlined />} 
                        style={{marginBottom: "15px"}} 
                    />
                    
                    <Checkbox onChange={(e) => setCheck(e.target.checked)} checked={check} style={{marginBottom: "10px", alignSelf: "flex-start", color: "#fff"}}>{t("check_login")}</Checkbox>
                    {cargando 
                        ? <Button type="ghost" loading style={{border: `1px solid ${getCongif("colorSecundario")}`, color: getCongif("colorSecundario"), width: "150px"}}>{t("loading")}</Button>
                        : <Button onClick={onLogin} type="ghost" style={{border: `1px solid ${getCongif("colorSecundario")}`, color: getCongif("colorSecundario"), width: "150px"}}>{t("btn_login")}</Button>
                    }
                </Flex>
            </>
        )
    }

    return (
        <Row style={{height: "100vh", backgroundColor: getCongif("fondo")}}>
            {contextHolder}

            <Col span={12}>
                <Flex align="center" justify="center" style={{ height: "100%"}}>
                    <Image width={350} src={getCongif("logo")==="" ? Logo : getCongif("logo")} />
                </Flex>
            </Col>

            <Col span={12}>
                <Flex align="center" justify="center" style={{position: "relative", height: "100%"}}>
                    <FormLogin />
                </Flex>
            </Col>
        </Row>
    )
}
