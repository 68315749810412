import React, { useState } from 'react'
// i18
import { useTranslation } from 'react-i18next'
// Ant Design
import { Input } from 'antd'
const { Search } = Input;

export const Buscador = ({setData, dataOriginal}) => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    // Para filtro de busqueda
    const [searchText, setSearchText] = useState("");
    const onSearch = (e) => {
        const {value} = e.target;
        setSearchText(value);
        onFilter(value);
    }
    const onFilter = (busqueda) => {
        const result = dataOriginal.filter((item) => 
            item.Key.toString().toLowerCase().includes(busqueda.toLowerCase())
        );
        setData(result);
    }

    return <Search onChange={(e) => onSearch(e)} value={searchText} placeholder={t("filter_name_file")} allowClear size="large" style={{ width: "80%" }} />
}
