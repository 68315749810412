import React, { useEffect, useState } from 'react'
// Ant Design
import { Table, Switch, Button, Tooltip, message, Popconfirm, Flex, Spin, Modal, Typography } from 'antd'
// Icons
import { DeleteOutlined, EyeOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
// Styles
import '../../styles/Tabla.css'
// React Router
import { useNavigate } from 'react-router-dom'
// Moment js
import moment from 'moment'
import 'moment/locale/es'
// i18
import { useTranslation } from 'react-i18next'
// Axios
import axios from 'axios'
// Config
import {API_URL, getCongif} from '../../../config'

const { Text } = Typography;

export const Tabla = ({dataApi, total, page, pageSize, setPage, setPageSize, cargando, apiCampañas}) => {
    // Para el idioma
    const { t, i18n } = useTranslation(["translation"]);

    const navigate = useNavigate();
    
    const columns = [
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_campaign")}</p>,
            dataIndex: 'campaña',
            align: 'center',
            width: 200,
            fixed: 'left',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_created")}</p>,
            dataIndex: 'creado',
            width: 250,
            align: 'center',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_total")}</p>,
            dataIndex: 'total',
            width: 80,
            align: 'center',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_scheduled")}</p>,
            dataIndex: 'programado',
            width: 120,
            align: 'center',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_sent")}</p>,
            dataIndex: 'enviado',
            width: 90,
            align: 'center',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_agent")}</p>,
            dataIndex: 'agente',
            width: 90,
            align: 'center',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_answered")}</p>,
            dataIndex: 'respuesta',
            width: 105,
            align: 'center',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_expired")}</p>,
            dataIndex: 'expirado',
            width: 100,
            align: 'center',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_error")}</p>,
            dataIndex: 'error',
            width: 80,
            align: 'center',
        },
        {
            title: <p style={{fontWeight: "700", fontSize: "15px"}}>{t("table_status")}</p>,
            dataIndex: 'estatus',
            width: 85,
            align: 'center',
        },
        {
            title: '',
            dataIndex: 'eliminar',
            width: 60,
            align: 'center',
        },
        {
            title: '',
            dataIndex: 'detalle',
            width: 60,
            align: 'center',
        },
        {
            title: '',
            dataIndex: 'descargar',
            width: 60,
            align: 'center',
        },
    ];

    // Para data de tabla
    const [data, setData] = useState([]);
    useEffect(() => {
        const arr = [];
        dataApi.map((item, index) => (
            arr.push({
                key: index+1,
                campaña: (
                    <>
                        {item.disabled
                            ? <div style={{position: "absolute", top: "0", left: "0", width: "10px", height: "100%", backgroundColor: "#FF0000"}}></div>
                            : <div style={{position: "absolute", top: "0", left: "0", width: "10px", height: "100%", backgroundColor: "#2CA438"}}></div>
                        }
                        <p>{item.campaignId}</p>
                    </>
                ),
                creado: formatearFechaEnEspanol(item.createdAt),
                total: item.count,
                programado: item.resumeStates.scheduled,
                enviado: item.resumeStates.sent,
                agente: item.resumeStates.agent,
                respuesta: item.resumeStates.answered,
                expirado: item.resumeStates.expired,
                error: item.resumeStates.failed,
                estatus: <Switch onChange={() => pauseCampaign(item.campaignId, item.disabled)} defaultChecked={item.disabled ? false : true} checkedChildren="Play" unCheckedChildren="Stop" />,
                eliminar: (
                    <Tooltip title={t("btn_delete")} color="#FF0000">
                        <Popconfirm
                            title={t("alert_title_campaign")}
                            description={t("alert_text_campaign")}
                            onConfirm={() => deleteCampaign(item.campaignId)}
                            okText={t("text_confirm")}
                            cancelText="No"
                            zIndex={999999}
                        >
                            <Button type="ghost" shape="circle" style={{color: "#FF0000", border: "1px solid #FF0000"}} icon={cargandoDelete ? <LoadingOutlined /> : <DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                ),
                detalle: (
                    <Tooltip title={t("btn_detail")} color="#F1D030">
                        <Button onClick={() => enviarObjDetalle(item.resumeStates, item.campaignId)} type="ghost" shape="circle" style={{color: "#F1D030", border: "1px solid #F1D030"}} icon={<EyeOutlined />} />
                    </Tooltip>
                ),
                descargar: (
                    <Tooltip title={t("btn_download")} color="#4472C5">
                        <Button onClick={() => dowloadCampaña(item.campaignId)} type="ghost" shape="circle" style={{color: "#4472C5", border: "1px solid #4472C5"}} icon={<DownloadOutlined />} />
                    </Tooltip>
                ),
            })
        ))
        setData(arr);
    }, [dataApi])

    // Formatear fecha
    const formatearFechaEnEspanol = (fecha) => {
        if(i18n.language === "en") {
            moment.locale('en');
        }
        else {
            moment.locale('es');
        }
        const fechaDos = moment(fecha, 'DD-MM-YYYY HH:mm:ss').format('MMMM Do YYYY, h:mm:ss a');
        const fechaMejorada = fechaDos.replace('º', '');
        return fechaMejorada;
    };

    const enviarObjDetalle = (estatus, id) => {
        const myObjectString = JSON.stringify(estatus);
        localStorage.setItem('estatus', myObjectString);
        navigate(`/detalle/${id}`);
    }

    const [messageApi, contextHolder] = message.useMessage();
    // Para eliminar campaña
    const MensajeError = () => {
        messageApi.open({
            type: 'error',
            content: t("message_error_delete"),
            duration: 6,
        });
    };
    const MensajeDelete = (name) => {
        messageApi.open({
            type: 'success',
            content: t("message_delete_success", {name: name}),
            duration: 6,
        });
    };

    const [cargandoDelete, setCargandoDelete] = useState(false)
    const deleteCampaign = async(name) => {
        setCargandoDelete(true)
        try {
            await axios.delete(API_URL + `/campaigns/${name}`,{
                withCredentials: true
            
            })
            MensajeDelete(name)
            setCargandoDelete(false)
            apiCampañas()
        }
        catch (error) {
            console.log(error)
            MensajeError()
            setCargandoDelete(false)
        }
    }

    // Para pausar o correr campaña
    const MensajePause = (name) => {
        messageApi.open({
            type: 'warning',
            content: t("message_pause_campaign", {name: name}),
            duration: 6,
        });
    };
    const MensajePlay = (name) => {
        messageApi.open({
            type: 'success',
            content: t("message_play_campaign", {name: name}),
            duration: 6,
        });
    };
    const MensajeAction = () => {
        messageApi.open({
            type: 'error',
            content: t("message_error"),
            duration: 6,
        });
    };

    const pauseCampaign = async(name, disabled) => {
        let texto = null
        if(disabled) {
            texto = "RESUMED"
        }
        else {
            texto = "PAUSED"
        }

        try {
            await axios.put(API_URL + `/campaigns/${name}/action/${texto}`, null, {
                withCredentials: true 
            }) 
            apiCampañas()
            if(texto === "PAUSED") {
                MensajePause(name)
            }
            else {
                MensajePlay(name)
            }
        }
        catch (error) {
            MensajeAction()
        }
    }

    // Alerta de error
    const Error = () => {
        messageApi.open({
            type: 'error',
            content: t("error_download"),
        });
    };

    // Para modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Para descargar campaña
    const dowloadCampaña = async(name) => {
        setIsModalOpen(true);
        try {
            const res = await axios.get(API_URL + `/campaigns/download/${name}`, {
                withCredentials: true,
                responseType: 'blob'
            })
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${name}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            if(res.status === 200) {
                setIsModalOpen(false);
            }
        }
        catch (error) {
            setIsModalOpen(false);
            Error();
        }
    }
    

    return (
        <div style={{marginTop: "20px", padding: "0 60px"}}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                    position: ['bottomCenter'],
                    onChange: (page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize);
                    },
                }}
                scroll={{
                    y: '80vh',
                }}
                loading={cargando ? true : false}
            />

            <Modal title={<div style={{fontSize: "22px", textAlign: "center", color: getCongif("colorPrimario")}}>{t("download_file")}</div>} open={isModalOpen} footer={null} closable={false}>
                <Flex vertical align="center" style={{marginTop: "20px"}}>
                    <Spin size="large" />
                    <Text style={{marginTop: "10px"}}>{t("wait_moment")}</Text>
                </Flex>
            </Modal>

            {contextHolder}
        </div>
    )
}
