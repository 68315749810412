import React from 'react'
// i18
import { useTranslation } from 'react-i18next'
// Ant Design
import { Select } from 'antd'

export const FiltroEstatus = ({setEstatus}) => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    const options = [];
    options.push(
        {
            label: t("table_scheduled"),
            value: "SCHEDULED",
        },
        {
            label: t("table_sent"),
            value: "SENT",
        },
        {
            label: t("table_agent"),
            value: "AGENT",
        },
        {
            label: t("table_answered"),
            value: "ANSWERED",
        },
        {
            label: t("table_expired"),
            value: "EXPIRED",
        },
        {
            label: t("table_error"),
            value: "FAILED",
        },
        {
            label: t("table_all"),
            value: "ALL",
        }
    );

    const handleChange = (value) => {
        if (value==="") {
            setEstatus(null)
        }
        else {
            setEstatus(value)
        }
    };

    return (
        <Select
            size="large"
            allowClear
            style={{width: "80%", marginTop: "10px"}}
            placeholder={t("filter_status")}
            onChange={handleChange}
            options={options}
        />
    )
}
