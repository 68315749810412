import React, { useEffect } from 'react'
// React Router
import { useNavigate } from 'react-router-dom'
// Layout
import { Navbar } from '../layout/Navbar'
// Ant Design
import { Typography } from 'antd'
// Fragments
import { BreadCrumb } from '../fragments/AgregarTemplate/BreadCrumb'
import { Formulario } from '../fragments/AgregarTemplate/Formulario'
import { BtnRegresar } from '../layout/BtnRegresar'
// Configuraciones
import { getCongif, API_URL } from '../../config'
// Axios
import axios from 'axios'

const { Title } = Typography;

export const AgregarTemplate = () => {

    const navigate = useNavigate()
    const isLogin = async() => {
        try {
            await axios.get(API_URL + "/auth/checkLogin",{
                withCredentials: true
            })
        }
        catch (error) {
            if(error.response.status === 401) {
                navigate("/login")
            }
        }
    }
    useEffect(() => {
        isLogin()
    }, [])

    return (
        <section>
            <Navbar />
            <BreadCrumb />

            <Title level={3} style={{color: getCongif("colorSecundario"), textAlign: "center"}}>AGREGAR TEMPLATE</Title>
            <Formulario />

            <BtnRegresar />
        </section>
    )
}
