import React, { useState } from 'react'
// i18
import { useTranslation } from 'react-i18next'
// Icons
import { RightOutlined, RetweetOutlined } from '@ant-design/icons'
// Ant Design
import { Flex, DatePicker, Button, Tooltip } from 'antd'
const { RangePicker } = DatePicker;

export const Filtro = ({setDateInicio, setDateFin}) => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    // Para obtener valores del RangePicker
    const [inicio, setInicio] = useState("");
    const [fin, setFin] = useState("");
    const [range, setRange] = useState(null);
    const [reset, setReset] = useState(false);

    // Función para obtener valores del RangePicker
    const getDates = (dates, dateStrings) => {
        setRange(dates);
        setInicio(convertDateFormat(dateStrings[0]));
        setFin(convertDateFormat(dateStrings[1]));
    }

    const convertDateFormat = (inputDate) => {
        const parts = inputDate.split('/');
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];    
        const formattedDate = `${day}-${month}-${year}`;    
        return formattedDate;
    };

    // Al dar click en el botón
    const filterDataByDateRange = () => {
        if (inicio !== "" || fin !== "") {
            setDateInicio(inicio);
            setDateFin(fin);
            setReset(true);
        }
    }

    // Al dar click en refresh
    const onRefresh = () => {
        setReset(false);
        setInicio("");
        setFin([]);
        setDateInicio(null);
        setDateFin(null);
        setRange(null);
    }

    const disabledDate = current => {
        return current && current > new Date();
    };

    return (
        <Flex style={{width: "50%"}} align="center">
            <RangePicker value={range} onChange={getDates} placeholder={[t("filter_datestart"), t("filter_dateend")]} format="DD/MM/YYYY" size="large" style={{width: "70%"}} disabledDate={disabledDate} />
            <Button onClick={filterDataByDateRange} type="ghost" size="large" shape="circle" style={{marginLeft: "5px", border: "1px solid #D7D7D7", color: "#D7D7D7"}} icon={<RightOutlined />} />

            {reset &&
                <Tooltip title="Reset" placement="top">
                    <Button onClick={onRefresh} type="ghost" size="large" shape="circle" style={{marginLeft: "5px", border: "1px solid #D7D7D7", color: "#D7D7D7"}} icon={<RetweetOutlined />} />
                </Tooltip>
            }
        </Flex>
    )
}
