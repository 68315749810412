import React, { useEffect, useState } from 'react'
// React Router
import { useNavigate } from 'react-router-dom'
// Ant Design
import { Flex } from 'antd'
// Layout
import { Navbar } from '../layout/Navbar'
// Fragments
import { BreadCrumb } from '../fragments/Archivos/BreadCrumb'
import { Buscador } from '../fragments/Archivos/Buscador'
import { Tabla } from '../fragments/Archivos/Tabla'
import { BtnRegresar } from '../layout/BtnRegresar'
// i18
import { useTranslation } from 'react-i18next'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../config'

export const Archivos = () => {
    // Para el idioma
    const { i18n } = useTranslation(["translation"]);

    const [data, setData] = useState([])
    const [dataOriginal, setDataOriginal] = useState([])
    const [cargando, setCargando] = useState(false)
    const [recargar, setRecargar] = useState(false)
    const navigate = useNavigate()

    // Consumo api de archivos
    const apiArchivos = async() => {
        setCargando(true)
        try {
            const res = await axios.get(API_URL + "/mediafiles", {
                withCredentials: true,
            })
            if(res.status === 200 || res.status === 204) {
                setData(res.data?.files)
                setDataOriginal(res.data?.files)
                setCargando(false)
            }
            else {
                setCargando(false)
            }
        }
        catch (error) {
            setCargando(false)
        }
    }

    const isLogin = async() => {
        try {
            await axios.get(API_URL + "/auth/checkLogin",{
                withCredentials: true
            })
        }
        catch (error) {
            if(error.response.status === 401) {
                navigate("/login")
            }
        }
    }

    useEffect(() => {
        apiArchivos()
        isLogin()
    }, [recargar, i18n.language])

    return (
        <section>
            <Navbar />

            <Flex justify="space-between" style={{padding: "10px"}}>
                <Flex style={{width: "50%"}}>
                    <BreadCrumb />
                </Flex>
                <Flex justify="flex-end" style={{width: "50%"}}>
                    <Buscador setData={setData} dataOriginal={dataOriginal} />
                </Flex>
            </Flex>

            <Tabla dataApi={data} cargando={cargando} recargar={recargar} setRecargar={setRecargar} />
            <BtnRegresar />
        </section>
    )
}
