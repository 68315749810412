import React from 'react'
// Ant Design
import { Flex, Input } from 'antd'
const { Search } = Input;

export const Buscador = () => {
    return (
        <Flex style={{width: "50%", zIndex: "10"}} justify="flex-end">
            <Search placeholder="Nombre o Template ID" allowClear size="large" style={{ width: "70%" }} />
        </Flex>
    )
}
